@import 'bootstrap/dist/css/bootstrap.min.css';

.employee-toast {
  padding-top: 70px;
  padding-right: 2rem;
}
body {
  line-height: 1;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul {
  list-style: none;
  color: #000;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  outline: 0 !important;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
.mb--95 {
  margin-bottom: -95px;
}
body {
  outline: none;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: #f7f8f8;
}
a:focus {
  outline: none;
  outline-offset: inherit;
}
button:active {
  border: none;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}
p {
  margin-bottom: 0;
}
ol {
  list-style: none;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
  margin-left: 0;
}
.form-control:focus {
  box-shadow: none;
  outline: none;
  outline-offset: inherit;
}
.btn_center {
  display: block;
  margin: 0 auto;
}
/*.wrapper {
    overflow: hidden;
}*/
.TypeDisabled {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebedee;
  border: 1px solid #ebedee;
  color: #8d9ba5;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
}
.TypeInfo {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeedfc;
  border: 1px solid #eeedfc;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
  color: #15125e;
}
.TypeNegative {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffebeb;
  border: 1px solid #ffebeb;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
  color: #5d0909;
}
.TypeNeutral {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ebedee;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
  color: #1f2b33;
}
.TypePositive {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbfae8;
  border: 1px solid #dbfae8;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
  color: #04431f;
}
.TypeWarning {
  padding: 2px 3px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe9e0;
  border: 1px solid #ffe9e0;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-size: 10px;
  text-transform: uppercase;
  box-shadow: none;
  color: #5c200a;
}
.badge-right-inner {
  background-color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 24px;
}
.badge-right-inner ul {
  border: 1px dashed #9747ff;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.badge-right-inner ul li {
  margin: 0 0 20px 0;
}
.badge-right-inner ul li:last-child {
  margin: 0;
}
.button-comp-right ul li {
  margin: 0 0 22px 0;
}
.button-comp-right ul li:last-child {
  margin: 0;
}
.botton-color-box {
  background-color: #ebedee;
}
/**** COLOR PRIMARY ****/
.ColorPrimary {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #ffffff;
}
.ColorPrimary span {
  margin-right: 5px;
  display: inline-block;
}
/**** COLOR SECONDARY ****/
.ColorSecondary {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #1469a6;
}
.ColorSecondary span {
  margin-right: 5px;
  display: inline-block;
}
/**** COLOR NEGATIVE ****/
.ColorNegative {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #ae1313;
}
.ColorNegative span {
  margin-right: 5px;
  display: inline-block;
}
/**** COLOR DISABLED ****/
.ColorDisabled {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #496477;
}
.ColorDisabled span {
  margin-right: 5px;
  display: inline-block;
}
/****** TYPES BUTTON *****/
.bnt-TypeDisabled {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #ebedee;
  border: 1px solid #ebedee;
  line-height: 1.33;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 32px;
  color: #496477;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  justify-content: center;
}
.bnt-TypeDisabled span {
  display: inline-block;
  margin-right: 5px;
}
/****** PRIMARY REST BTN *****/
.TypePrimary-Rest {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #3482ba;
  border: 1px solid #3482ba;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 32px;
  color: #ffffff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.TypePrimary-Rest span {
  display: inline-block;
  margin-right: 5px;
}
.TypePrimary-Rest:hover {
  background-color: #1469a6;
}
.TypePrimary-Rest:focus {
  border: 2px solid #539cce;
}
/****** SECONDARY REST BTN *****/
.TypeSecondary-Rest {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 32px;
  color: #1469a6;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.TypeSecondary-Rest span {
  display: inline-block;
  margin-right: 5px;
}
.TypeSecondary-Rest:hover {
  background-color: #f7f8f8;
}
.TypeSecondary-Rest:focus {
  border: 2px solid #539cce;
}
/****** NEGATIVE REST BTN *****/
.TypeNegative-Rest {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 32px;
  color: #ae1313;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.TypeNegative-Rest-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  color: #ae1313;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.TypeNegative-Rest span {
  display: inline-block;
  margin-right: 5px;
}
.TypeNegative-Rest:hover {
  background-color: #f7f8f8;
}
.TypeNegative-Rest:focus {
  border: 2px solid #d8dcde;
}
/****** BOX TYPE BTN *****/
.Box-type {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #3482ba;
  border: 1px solid #3482ba;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 32px;
  color: #ffffff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.Box-type span {
  display: inline-block;
  margin-right: 5px;
}
/****** BOX TYPE MEDIUM BTN *****/
.Box-type-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #3482ba;
  border: 1px solid #3482ba;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  color: #ffffff;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.Box-type-medium span {
  display: inline-block;
  margin-right: 5px;
}
/****** BOX TYPE MEDIUM SECOND BTN *****/
.Box-type-medium-second {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  line-height: 1.33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  color: #3482ba;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.Box-type-medium-second span {
  display: inline-block;
  margin-right: 5px;
}
.check-button-comp-right .components-box {
  max-width: 60px;
  width: 100%;
}
.check-button-comp-right ul li {
  margin: 0 0 20px 0;
}
.check-button-comp-right ul li:last-child {
  margin: 0;
}
/***** STATE CHECKED ******/
.StateChecked {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #d8dcde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.StateChecked:focus {
  border-color: #539cce;
}
.StateChecked:hover {
  border-color: #9fcdef;
}
.StateChecked:checked[type='checkbox'] {
  background-image: url(/src/pages/assets/check-icon.svg);
}
.StateChecked:checked {
  background-color: #3482ba;
  border-color: #3482ba;
}
.StateChecked:checked:hover {
  background-color: #1469a6;
}
.StateChecked:checked:focus {
  border-color: #9fcdef;
  background-color: #3482ba;
}
.StateChecked:disabled {
  pointer-events: none;
  filter: none;
  background-color: #ebedee;
  border-color: #ebedee;
}
.StateChecked.StateInvalid {
  border-color: #eb4847;
}
.StateChecked.StateInvalid {
  border-color: #eb4847;
}
.StateChecked.StateInvalid:checked {
  border-color: #eb4847;
  background-color: #eb4847;
}
/***** STATE CHECKED ******/
.StateDisabled---Indetermined {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #d8dcde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.StateDisabled---Indetermined:focus {
  border-color: #539cce;
}
.StateDisabled---Indetermined:hover {
  border-color: #9fcdef;
}
.StateDisabled---Indetermined:checked[type='checkbox'] {
  background-image: url(/src/pages/assets/sm_minus.svg);
}
.StateDisabled---Indetermined:checked {
  background-color: #3482ba;
  border-color: #3482ba;
}
.StateDisabled---Indetermined:checked:hover {
  background-color: #1469a6;
}
.StateDisabled---Indetermined:checked:focus {
  border-color: #9fcdef;
  background-color: #3482ba;
}
.StateDisabled---Indetermined:disabled {
  pointer-events: none;
  filter: none;
  background-color: #ebedee;
  border-color: #ebedee;
}
.No-items {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  color: #8d9ba5;
  display: block;
  margin: 0;
  padding: 0;
}
.Has-items {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  color: #1469a6;
  display: block;
  margin: 0;
  padding: 0;
}
.CounterNo {
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #8d9ba5;
  display: flex;
  align-items: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  width: 16px;
  height: 12px;
  justify-content: center;
}
.CounterYes {
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  display: block;
  background-color: #e5f1fb;
  width: 16px;
  height: 12px;
  color: #1469a6;
  display: flex;
  align-items: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  justify-content: center;
}
/***** POSITION *****/
.PositionStart {
  background-color: #ffffff;
  -webkit-border-radius: 24px 0 0px 24px;
  -moz-border-radius: 24px 0 0px 24px;
  border-radius: 24px 0 0px 24px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.PositionMiddle {
  background-color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 8px 16px;
  border-left: 1px solid #ebedee;
  display: flex;
  align-items: center;
}
.PositionEnd {
  background-color: #ffffff;
  -webkit-border-radius: 0 24px 24px 0;
  -moz-border-radius: 0 24px 24px 0;
  border-radius: 0 24px 24px 0;
  padding: 8px 16px;
  border-left: 1px solid #ebedee;
  display: flex;
  align-items: center;
}
.Has-items {
  position: relative;
  margin-left: 7px;
  display: flex;
  align-items: center;
}
.Has-items .CounterYes {
  margin: -10px 0 0 5px;
}
.position-component {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
}
.position-component li {
  width: 33.3%;
}
.HeightSmall .btn-secondary {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1469a6;
  padding: 0;
}
.HeightSmall .btn-secondary span {
  width: 32px;
  height: 32px;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
  background-color: #ffffff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.HeightMedium .btn-secondary {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1469a6;
  padding: 0;
}
.HeightMedium .btn-secondary span {
  width: 40px;
  height: 40px;
  box-shadow: 1px 4px 8px 0 rgba(31, 43, 51, 0.1);
  background-color: #ffffff;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.dropdown-menu-inner {
  box-shadow: 2px 1px 10px 0 rgba(31, 43, 51, 0.1);
  max-height: 450px;
  overflow-y: auto;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.dropdown-menu-inner li {
  margin: 0 !important;
}
.dropdown-menu {
  border: none;
  padding: 0;
  box-shadow: 2px 1px 10px 0 rgba(31, 43, 51, 0.1);
  max-height: 450px;
  overflow-y: auto;
}
.dropdown-menu li {
  margin: 0 !important;
}
.dropdown-menu li button.dropdown-item {
  border-bottom: 1px solid #ebedee;
  color: #0b3a5b;
  line-height: 1.43;
  font-size: 14px;
  padding: 12px;
}
.dropdown-menu li button.dropdown-item:hover {
  background-color: #f7f8f8;
  color: #0b3a5b;
}
.dropdown-menu li button.dropdown-item:focus {
  background-color: #e5f1fb;
  color: #0b3a5b;
}
.dropdown-menu li button.StateDisabled {
  color: #8d9ba5;
  pointer-events: none;
  filter: none;
}
:focus-visible {
  outline: none;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
/**** MEMU ITEM ******/
.StateDisabled {
  border-bottom: 1px solid #ebedee;
  display: block;
  width: 100%;
  line-height: 1.43;
  font-size: 14px;
  padding: 12px;
  color: #8d9ba5;
  pointer-events: none;
  filter: none;
}
.StateHover {
  border-bottom: 1px solid #ebedee;
  display: block;
  width: 100%;
  line-height: 1.43;
  font-size: 14px;
  padding: 12px;
  color: #0b3a5b;
  background-color: #f7f8f8;
}
.StateHover:hover {
  color: #0b3a5b;
}
.StateRest {
  border-bottom: 1px solid #ebedee;
  display: block;
  width: 100%;
  line-height: 1.43;
  font-size: 14px;
  padding: 12px;
  color: #0b3a5b;
  text-align: left;
  background-color: #ffffff;
}
.StateRest:hover {
  color: #0b3a5b;
}
.StateSelected {
  border-bottom: 1px solid #ebedee;
  display: block;
  width: 100%;
  line-height: 1.43;
  text-align: left;
  font-size: 14px;
  padding: 12px;
  background-color: #e5f1fb;
  color: #0b3a5b;
}
.StateSelected:hover {
  color: #0b3a5b;
}
.CheckboxYes {
  border-bottom: 1px solid #ebedee;
  width: 100%;
  line-height: 1.43;
  font-size: 14px;
  padding: 12px;
  color: #0b3a5b;
  background-color: #ffffff;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.CheckboxYes span {
  display: inline-block;
  margin-left: 10px;
}
.CheckboxYes label {
  display: inline-block;
  margin-left: 10px;
}
.CheckboxYes:hover {
  color: #0b3a5b;
}
.feild-number {
  max-width: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Neutral-Low-contrast {
  line-height: 1.43;
  font-size: 14px;
  color: #8d9ba5;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.Neutral-Medium-contrast {
  line-height: 1.43;
  font-size: 14px;
  color: #496477;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.Neutral-High-contrast {
  line-height: 1.43;
  font-size: 14px;
  color: #1f2b33;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.Neutral-Negative-contrast {
  line-height: 1.43;
  font-size: 14px;
  color: #5d0909;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.field-relative {
  position: relative;
}
.field-relative .form-control {
  border: 1px solid #d8dcde;
  background-color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  height: 44px;
  padding: 12px 8px 12px 20px;
  box-shadow: none;
  text-align: right;
  color: #1f2b33;
}
.field-relative .form-control::placeholder {
  color: #1f2b33;
}
.field-relative .form-control:focus {
  border-color: #539cce;
}
.field-relative .form-control:disabled {
  background-color: #ebedee;
  border-color: #ebedee;
  color: #8d9ba5;
}
.field-relative .form-control:disabled::placeholder {
  color: #8d9ba5;
}
.field-relative .form-control.BoxNegative {
  border-color: #eb4847;
  color: #5d0909;
}
.field-relative .form-control.BoxNegative::placeholder {
  color: #5d0909;
}
.field-relative .form-control.BoxNon-editable {
  border-color: #ebedee;
  background-color: #ebedee;
  color: #496477;
  pointer-events: none;
}
.field-relative .form-control.BoxNon-editable::placeholder {
  color: #496477;
}
.currency {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 8px;
}
.label-text {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #496477;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
}
.label-text img {
  margin-left: 9px;
}
sup {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #496477;
  font-size: 12px;
  top: 0;
  margin: 0 0 0 4px;
}
.helper-text {
  line-height: 1.33;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #496477;
  font-size: 12px;
  margin: 8px 0 0 0;
}
.helper-Negative {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  color: #5d0909;
  font-size: 12px;
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;
}
.helper-Negative img {
  margin-right: 6px;
}
.Some-link {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1469a6;
  display: inline-flex;
  line-height: 1.33;
  align-items: center;
  border-bottom: 1px solid transparent;
}
.Some-link:hover {
  border-color: #1469a6;
  color: #1469a6;
}
.Some-link:focus {
  border-bottom-style: dashed;
  color: #1469a6;
  border-color: #1469a6;
}
.Some-link.disabled {
  color: #8d9ba5;
  pointer-events: none;
}
.Some-link img {
  margin-left: 8px;
}
.info-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #15125e;
  padding: 0 0 0 17px;
}
.info-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #4843c5;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.Negative-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #5d0909;
  padding: 0 0 0 17px;
}
.Negative-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #d52020;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.Neutral-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #1f2b33;
  padding: 0 0 0 17px;
}
.Neutral-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #6a7f8e;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.Positive-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #04431f;
  padding: 0 0 0 17px;
}
.Positive-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #26a65e;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.Warning-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #5c200a;
  padding: 0 0 0 17px;
}
.Warning-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #e75b27;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.open-status {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #0b3a5b;
  padding: 0 0 0 17px;
}
.open-status::after {
  position: absolute;
  content: '';
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  background-color: #3482ba;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.alert-box {
  margin-bottom: 18px;
}
.alert-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  /* number of lines to show */
  /* line-clamp: 2; */
  flex: 1;
  -webkit-box-orient: vertical;
  padding: 0 10px 0 25px;
  position: relative;
  text-align: left;
}
.alert-modal {
  background-color: #f7f8f8;
  border-left: 3px solid #6a7f8e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 17px;
  min-height: 56px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
.alert-modal ol {
  display: flex;
  align-items: center;
}
.alert-modal ol li {
  margin-right: 20px;
}
.alert-modal ol li:last-child {
  margin: 0px !important;
}
/***** ALERT INFO ******/
.alert-info {
  background-color: #eeedfc;
  border-left: 3px solid #4843c5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 17px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
  min-height: 56px;
}
.alert-info ol {
  display: flex;
  align-items: center;
}
.alert-info ol li {
  margin-right: 20px !important;
  margin-bottom: 0 !important;
}
.alert-info ol li:last-child {
  margin: 0px !important;
}
/***** ALERT NEGATIVE ******/
.alert-Negative {
  background-color: #ffebeb;
  border-left: 3px solid #d52020;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 17px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
.alert-Negative ol {
  display: flex;
  align-items: center;
}
.alert-Negative ol li {
  margin-right: 20px !important;
  margin-bottom: 0 !important;
}
.alert-Negative ol li:last-child {
  margin: 0px !important;
}
/***** ALERT POSITIVE ******/
.alert-Positive {
  background-color: #dbfae8;
  border-left: 3px solid #26a65e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 12px 12px 12px 17px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
.alert-Positive ol {
  display: flex;
  align-items: center;
}
.alert-Positive ol li {
  margin-right: 20px !important;
  margin-bottom: 0 !important;
}
.alert-Positive ol li:last-child {
  margin: 0px !important;
}
/***** ALERT WARNING ******/
.alert-Warning {
  background-color: #ffe9e0;
  border-left: 3px solid #e75b27;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 17px;
  min-height: 56px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
.alert-Warning ol {
  display: flex;
  align-items: center;
}
.alert-Warning ol li {
  margin-right: 20px !important;
  margin-bottom: 0 !important;
}
.alert-Warning ol li:last-child {
  margin: 0px !important;
}
.some-neutral_color {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #1f2b33;
  font-size: 14px;
  line-height: 1.33;
}
.some-neutral_color span {
  display: inline-block;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}
.some-info_blue {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #15125e;
  font-size: 14px;
  line-height: 1.33;
}
.some-info_blue span {
  display: inline-block;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}
.some-negative_color {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #5d0909;
  font-size: 14px;
  line-height: 1.33;
}
.some-negative_color span {
  display: inline-block;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}
.some-positive_color {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #04431f;
  font-size: 14px;
  line-height: 1.33;
}
.some-positive_color span {
  display: inline-block;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}
.some-warning_color {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  color: #5c200a;
  font-size: 14px;
  line-height: 1.33;
}
.some-warning_color span {
  display: inline-block;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}
.metric-title {
  color: #496477;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.33;
}
.ComparisonDown---Negative {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #ae1313;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComparisonDown---Negative span {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ComparisonDown---Neutral {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #496477;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.33;
}
.ComparisonDown---Neutral span {
  /* width: 16px; */
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ComparisonDown---Positive {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #08873f;
  font-size: 12px;
  display: flex;
  justify-content: center;
  line-height: 1.33;
  align-items: center;
}
.ComparisonDown---Positive span {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ComparisonDown---variation {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #496477;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComparisonDown---variation span {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ComparisonDown---variation span::after {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  background-color: #496477;
}
.Data-type-numnber {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #1f2b33;
  font-size: 16px;
  line-height: 1.33;
}
.data-type-progress {
  margin: 30px 0;
}
.data-type-progress .progress {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 8px;
  background-color: #ebedee;
}
.data-type-progress .progress .progress-bar {
  background-color: #3482ba;
}
.data-type-rating {
  display: flex;
  align-items: center;
}
.data-type-rating ol {
  display: flex;
  align-items: center;
}
.data-type-rating ol li {
  margin-right: 4px;
}
.data-type-rating span {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #496477;
  font-size: 14px;
  line-height: 1.43;
  display: inline-block;
}
.Metric-item {
  padding: 12px;
}
.Metric-item .Data-type-numnber {
  padding: 10px 0 6px;
}
.Metric-item .media {
  display: flex;
  align-items: center;
}
.Stacking {
  padding: 20px 0;
}
.Stacking .Stacking-left h4 {
  color: #1469a6;
  line-height: 1.33;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.Stacking .Stacking-left p {
  color: #1f2b33;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
}
.Stacking .Stacking-right p {
  color: #1f2b33;
  line-height: 1.33;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.box-btn {
  padding: 8px;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  color: #1469a6;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.label-cell {
  color: #496477;
  line-height: 1.33;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  display: block;
}
.number-text {
  font-size: 12px;
  line-height: 1.33;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #1f2b33;
}
.Header-row-card {
  display: flex;
}
.Header-row-card-right {
  flex: 1;
  width: 100%;
  padding-left: 24px;
}
.Header-row-card-right ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.Header-row-card-right ul li {
  margin: 0 0 6px 0 !important;
  width: 50%;
}
.Header-row-card-right ul li .Neutral-status {
  display: inline-flex;
}
.sorting {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #1f2b33;
  font-size: 12px;
  display: flex;
  line-height: 1.33;
  align-items: center;
}
.Check-sign {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  background-color: #ebedee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #ebedee;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.Check-sign:checked {
  border-color: #ebedee;
  background-color: #ffffff;
}
.Check-sign:checked[type='checkbox'] {
  background-image: url(/src/pages/assets/check-green.svg);
}
.todo-card {
  display: flex;
  align-items: center;
}
.todo-card .todo-card-right {
  flex: 1;
  width: 100%;
  border: 1px solid #ebedee;
  background-color: #ffffff;
  padding: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  /* margin: 0 0 0 15px; */
}
.todo-card .todo-card-right .media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}
.type-success {
  display: flex;
  align-items: center;
}
.type-success .type-success-right {
  flex: 1;
  width: 100%;
  margin-left: 20px;
  background-color: #dbfae8;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 19px;
}
.type-success .type-success-right.task-complete {
  background-color: #ebedee;
}
.completedTask {
  margin-bottom: 12px;
  background-color: #ebedee;
  padding: 7% 6%;
  border-radius: 7px;
}
.TypeCollapsed a {
  border: 1px solid #ebedee;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header {
  background-color: #ffffff;
}
.components-card {
  padding: 120px 0 50px 0px;
}
.containerHeightSetter {
  min-height: 100vh;
}
.heading {
  margin-bottom: 16px;
}
.heading h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #1f2b33;
  font-size: 20px;
  position: relative;
  padding: 0 0 10px 0;
}
.heading h2::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 32px;
  height: 2px;
  background-color: #3482ba;
}
.card {
  border: none;
  background-color: #ffffff;
  padding: 30px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card .image-holder {
  max-width: 55px;
  width: 100%;
}
.card .card-box {
  flex: 1;
  width: 100%;
  padding-left: 30px;
}
.card .card-box span {
  position: static !important;
}
.card .card-box ul li {
  margin: 0 0 11px 0;
}
.card .card-box ul li p {
  line-height: 1.43;
}
.card .card-box ul li p strong {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  flex: 1;
}
.card .card-box ul li:last-child {
  margin: 0;
}
.heading-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-right ol {
  display: flex;
  align-items: center;
}
.heading-right ol li {
  margin-left: 10px;
}
.metrics-performance {
  background-color: #ffffff;
  border: 1px solid #ebedee;
  margin: 20px 0 0 0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.metrics-performance ol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 -1px 0;
}
.metrics-performance ol li.performance-item {
  border-bottom: 1px solid #ebedee;
  width: 12.5%;
  min-height: 110px;
}
.metrics-performance ol li.performance-item .data-type-progress {
  margin: 15px 0;
}
.order-media .heading-right ol {
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}
.order-media .heading-right ol li {
  margin: 0;
}
.order-detail {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 12px;
  margin: 0px 0 0 0;
}
.border-bottom {
  border-bottom: 1px solid #ebedee !important;
}
.progress-height .progress {
  height: 16px;
}
.progress-height .progress .progress-bar {
  font-size: 10px;
  text-align: right;
  padding: 0 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.todo-setup ul li {
  margin: 0 0 10px 0;
}
.todo-card-right.media2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-left {
  background-color: #ffffff;
  padding: 95px 40px 20px 80px;
  position: fixed;
  left: 0;
  top: 0;
  max-width: 430px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}
.dashboard-left::after {
  position: absolute;
  content: '';
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.dashboard-left .dashboard-box {
  margin: 0 0 15px 0;
}
.dashboard-left .dashboard-box:last-child {
  margin: 0;
}
.dashboard-left .card {
  padding: 10px 15px;
}
.dashboard-left .card-box .some-neutral_color span {
  position: static;
}
.dashboard-right {
  padding: 96px 80px 40px 470px;
  background-color: #f7f8f8;
}
.dashboard-detail {
  margin: 0 0 24px 0;
  background-color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 24px;
}
.dashboard-detail .metrics-performance ol li.performance-item {
  width: 16.6%;
  min-height: 110px;
}
.invoice-table {
  margin-top: 16px;
}
table {
  width: 100%;
}
table thead tr th {
  padding: 16px 12px;
}
table tbody tr td {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #1f2b33;
  font-size: 12px;
  line-height: 1.33;
  padding: 16px 12px;
}
table tbody tr td h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1469a6;
}
.table-striped tr:nth-child(even) {
  background: #f7f8f8;
}
.table-shadow {
  box-shadow: 1px 2px 8px 0 rgba(31, 43, 51, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.box-btn-big {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.sorting-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 0 0;
}
.sorting-table .sorting {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
.sorting-table .sorting strong {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination ul li {
  margin: 0 6px;
}
.pagination-item {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.33;
  color: #1f2b33;
  font-size: 12px;
}
.components-box-second .Header-row-card-right {
  margin-left: 12px;
  padding: 12px;
}
/***** MODAL ******/
.modal-dialog {
  max-width: 564px;
  width: 100%;
}
.modal-dialog .modal-content {
  background-color: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: none;
}
.modal-dialog .modal-content .modal-body {
  padding: 24px;
}
.invoice-modal-inner {
  padding-left: 35px;
}
.invoice-media {
  display: flex;
  margin: 0 0 20px 0;
}
.invoice-media .info-holder {
  max-width: 24px;
  width: 100%;
}
.invoice-media .info-holder span {
  display: block;
}
.invoice-media .info-holder span svg {
  width: 100%;
  height: auto;
}
.invoice-media .text-box {
  flex: 1;
  width: 100%;
  padding-left: 10px;
}
.invoice-media .text-box h2 {
  color: #1f2b33;
  line-height: 1.33;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 16px 0;
}
.invoice-media .text-box p {
  color: #1f2b33;
  line-height: 1.33;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.invoice-media .text-box p a {
  color: #1469a6;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.amount-form {
  border: 1px solid #d8dcde;
  background-color: #f7f8f8;
  height: 44px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  padding: 12px 8px;
  box-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  line-height: 1.33;
  color: #8d9ba5;
  font-size: 14px;
}
.required-text {
  line-height: 1.33;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #8d9ba5;
  font-size: 12px;
}
.dropping-box {
  margin: 0 0 20px 0;
}
.dropping-area {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border: 1px dashed #9fcdef;
  background-color: #e5f1fb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #496477;
  /* padding: 23px 24px; */
}
.dropAreaPadder {
  padding: 25px 0px;
}
.dropping-area .box-btn {
  margin-left: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  box-shadow: 1px 2px 4px 0 rgba(31, 43, 51, 0.1);
  padding: 8px 10px;
}
.dropping-area .box-btn img {
  margin-right: 5px;
}
.dropping-area input[type='file'] {
  display: none;
}
.dragging-box .dropping-area {
  flex-direction: column;
}
.dragging-box .dropping-area p {
  margin: 24px 0 0 0;
}
.dragging-box .dropping-area p img {
  height: 110px;
}
.dragging-box .dropping-area .data-type-progress {
  width: 100%;
}
.sidenave {
  position: fixed;
  left: -100%;
  padding: 70px 0;
  top: 0;
  height: 100%;
  background-color: #ffffff;
  width: 230px;
  z-index: 1090;
  transition: all 0.5s ease-in-out;
}
.sidenave.active {
  left: 0;
  transition: all 0.5s ease-in-out;
}
.sidenave ul li a {
  padding: 10px 20px;
  display: block;
}
.sidenave ul li .dropdown {
  padding: 10px 20px;
}
.header-second {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
}
header {
  padding: 11px 70px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedee;
  position: relative;
  z-index: 10000;
}
header .header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .header-inner .header-left {
  display: flex;
  align-items: center;
}
header .header-inner .header-right {
  display: flex;
  align-items: center;
}
.menu-btn {
  border: none;
  background-color: transparent;
}
.menu-btn p {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  margin-top: 7px;
  cursor: pointer;
}
.menu-btn p span {
  background-color: #1469a6;
  width: 18px;
  height: 2px;
  display: block;
  margin: 0 0 4px 0;
}
.menu-btn a span:last-child {
  margin: 0;
}
.logo {
  margin-left: 16px;
}
.logo a {
  display: flex;
  align-items: center;
}
.language-list ol {
  display: flex;
  align-items: center;
}
.language-list ol li {
  margin-left: 2px;
}
.language-list ol li button {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #1469a6;
  font-size: 12px;
  line-height: 1.33;
  display: block;
  border-bottom: 2px solid transparent;
  padding: 0 5px 3px;
}
.language-list ol li:hover button {
  border-bottom-color: #1469a6;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.language-list ol li.active button {
  border-bottom-color: #1469a6;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.service-list ol {
  display: flex;
  align-items: center;
}
.service-list ol li {
  margin-right: 22px;
}
.call-btn {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #1469a6;
  line-height: 1.33;
  font-size: 14px;
  gap: 7px;
  cursor: pointer;
}
.gp {
  gap: 2%;
}
.call-btn img {
  margin-right: 6px;
}
.mobile-menu {
  display: none;
}
.mobile-menu.active {
  display: block;
}
.crose-btn {
  display: none;
  width: 18px;
  height: 14px;
}
.crose-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: -9px 0 0 0;
}
.crose-btn a span:first-child {
  left: 15px;
  height: 18px;
  width: 2px;
  background-color: #1469a6;
  transform: rotate(-45deg) translate(-3px, 10px);
}
.crose-btn a span:last-child {
  left: 15px;
  height: 18px;
  width: 2px;
  transform: rotate(45deg) translate(-2px, -10px);
  background-color: #1469a6;
}
.wrapper {
  overflow: hidden;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}
.inner-width {
  max-width: 650px;
  width: 100%;
}
.wrapper-second {
  max-width: 932px;
}
.components-sec {
  padding: 40px;
}
/***** TITLE *****/
.title {
  padding-bottom: 8px;
  border-bottom: 2px solid #496477;
}
.title h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.6px;
  color: #496477;
  text-transform: uppercase;
}
.components-detail {
  padding: 25px 0 0 0;
  display: flex;
}
.components-detail .components-heading {
  max-width: 180px;
  width: 100%;
  padding-right: 5px;
}
.components-detail .components-heading h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: #496477;
  position: relative;
}
.components-detail .components-heading h3 span {
  display: inline-block;
  background-color: #f7f8f8;
  padding-right: 10px;
  position: relative;
  z-index: 10;
}
.components-detail .components-heading h3::after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 1px;
  background-color: #ebedee;
}
.components-detail .components-right {
  flex: 1;
  width: 100%;
  padding-left: 5px;
}
.components-detail .components-right .components-box {
  border: 1px dashed #9747ff;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.components-detail .components-right .components-box ul li {
  margin: 0 0 20px 0;
}
.components-detail .components-right .components-box ul li:last-child {
  margin: 0;
}
.components-detail .metric-right {
  flex: inherit;
  width: auto;
}
/***** COMPONENTS FOOTER ******/
.components-footer {
  border-top: 1px solid #ebedee;
  background-color: #ffffff;
  padding: 24px 40px;
  text-align: center;
}
.components-footer h3 {
  position: relative;
  color: #496477;
  font-size: 12px;
  line-height: 1.33;
  margin: 0 0 24px 0;
}
.components-footer h3 span {
  display: inline-block;
  background-color: #ffffff;
  padding: 0 10px;
  position: relative;
  z-index: 10;
}
.components-footer h3::after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 1px;
  background-color: #ebedee;
}
.components-footer .media {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orders-table {
  border-radius: 12px;
  background-color: white;
  padding: 0px 2%;
}

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}
.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}
.text-center {
  padding: 23px 24px;
}
.dropzone {
  padding: 23px 24px;
  margin: auto;
}
.errorMsg {
  color: red;
  font-size: 13px;
  font-weight: 600;
}
.paymentMethodBox {
  display: flex;
  justify-content: flex-start;
  padding-left: 11%;
}
.paymentMethodIcon {
  width: 6px;
  height: 19px;
  background-color: black;
  margin-right: 14px;
  cursor: pointer;
}

.orderDetailHeading {
  font-size: 1.1rem;
  font-weight: 700;
}
.orderDetailCommentBox {
  /* height: 103px; */
  width: 100%;
  border: 1px solid black;
  margin-top: 9px;
  border-radius: 5px;
  color: #8080808c;
}
.orderDetailAlertBox {
  height: 85px;
  width: 106%;
  /* border: 2px solid;
  margin-top: 10px; 
  display: flex; */
}
.orderDetailAlertBox p {
  align-self: center;
  font-weight: 500;
}
.orderDetailButtonGroup {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.orderDetailPaymentContainer {
  margin-top: 15%;
  margin-bottom: 50%;
}
.orderDetailButton {
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid;
  background: #ececed;
}
.totalPaymentText p {
  font-size: 13px;
}
.orderDetailTableLabel {
  text-align: left;
}
table.table-bordered {
  border: 2px solid #d0d0d0;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 2px solid #d0d0d0;
}
table.table-bordered > tbody > tr > td {
  border: 2px solid #d0d0d0;
}
.tableSecondaryheadingBorder {
  border-bottom: none !important;
}
.acceptOrderModal {
  min-width: 50%;
}
.rejectModalText {
  margin-top: 5px;
  display: flex;
  gap: 2%;
}
.paddingForDisabledBtn {
  padding: 8px;
  pointer-events: none;
}

.settingsMediaBox {
  padding: 1% 2%;
  border: 1px solid#D8DCDE;
  border-radius: 4px;
}
.settingsSubheading {
  font-size: 14px;
  font-weight: 500;
}
.settingsSubTextColor {
  color: #496477;
  font-weight: 600;
}
.lineTable {
  border-bottom: 1px solid rgba(73, 100, 119, 0.2);
}
.dropdown-container {
  border: 1px solid #e20c0c;
}
.img-container {
  position: relative;
  min-width: 204px;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.img-container:hover .image {
  opacity: 0.3;
}

.img-container:hover .middle {
  opacity: 1;
}
.img-container-text {
  background-color: #000;
  color: white;
  font-size: 16px;
  width: 204px;
  height: 164px;
  opacity: 0.7;
  cursor: pointer;
}
.closeButtonModal {
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  padding: 10px;
}

.filter-text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: inherit;
}

.profileText {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #496477;
}
.profileTextPhone {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #496477;
}

@media (max-width: 1300px) {
  header {
    padding: 11px 15px;
  }
  .dashboard-left {
    padding: 80px 30px 20px 30px;
    max-width: 365px;
  }
  .dashboard-right {
    padding: 80px 30px 30px 380px;
  }
  .dashboard-detail .metrics-performance ol li.performance-item {
    min-height: 111px;
  }
}
@media (max-width: 1199px) {
  .metrics-performance ol li.performance-item {
    width: 16.6%;
    min-height: 101px;
  }
  .dashboard-detail .metrics-performance ol li.performance-item {
    width: 20%;
    min-height: 110px;
  }
}
.additionalData {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
@media (max-width: 991px) {
  header {
    padding: 15px 0;
  }
  .metrics-performance ol li.performance-item {
    width: 25%;
  }
  .header-second {
    position: relative;
  }
  .mobile-menu {
    padding: 30px 15px;
    position: absolute;
    left: 0;
    top: 56px;
    border-bottom: 1px solid #ebedee;
    background: #fff;
    width: 100%;
    z-index: 10;
  }
  .dashboard {
    padding: 30px 0;
  }
  .dashboard-left {
    padding: 20px;
    max-width: 100%;
    position: static;
  }
  .dashboard-left .card .image-holder {
    max-width: 35px;
    width: 100%;
  }
  .dashboard-left .card .card-box {
    padding-left: 15px;
  }
  .dashboard-left .some-neutral_color {
    font-size: 12px;
  }
  .dashboard-left .card .card-box ul li {
    margin: 0 0 8px 0;
  }
  .dashboard-left::after {
    display: none;
  }
  .dashboard-right {
    padding: 0;
    margin: 20px 0 0;
  }
  .dashboard-detail {
    padding: 20px;
  }
  .components-detail {
    flex-direction: column;
  }
  .components-detail .components-right {
    padding-left: 0;
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
  .dashboard {
    padding: 30px 0;
  }
  .dashboard-detail .metrics-performance ol li.performance-item {
    width: 25%;
    min-height: 110px;
  }
  header .header-inner .header-right {
    flex-direction: row-reverse;
  }
  .language-list {
    margin-right: 10px;
  }
}
@media (max-width: 575px) {
  .components-sec {
    padding: 20px;
  }
  .components-detail {
    flex-direction: column;
  }
  .components-detail .components-heading {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
    margin: 0 0 20px 0;
  }
  .components-detail .components-right {
    padding-left: 0;
  }
  .badge-right-inner {
    padding: 15px;
  }
  .badge-right-inner ul {
    padding: 15px;
  }
  .check-button-comp-right .components-box {
    max-width: 100%;
    width: 100%;
  }
  .components-detail .components-right .components-box {
    padding: 15px;
  }
  .components-footer {
    padding: 20px 20px;
  }
  .todo-card .todo-card-right {
    margin: 0 0 0 10px;
  }
  .todo-card .todo-card-right {
    padding: 16px;
  }
  .card .card-box ul li {
    margin: 0 0 10px 0;
  }
  .heading-media {
    align-items: flex-start;
    flex-direction: column;
  }
  .heading-right ol {
    margin: 20px 0 0 0;
  }
  .heading-right ol li:first-child {
    margin: 0;
  }
  .metrics-performance ol li.performance-item {
    width: 50%;
  }
  .order-media {
    flex-direction: row;
  }
  .todo-card-right.media2 {
    align-items: flex-start;
    flex-direction: column;
  }
  .todo-card-right.media2 .media {
    width: 100%;
    margin: 16px 0 0 0 !important;
    flex-direction: row-reverse;
    padding-left: 12px;
  }
  .todo-card-right.media2 .media button {
    margin: 0 !important;
  }
  .dashboard-detail .metrics-performance ol li.performance-item {
    width: 50%;
    min-height: 110px;
  }
  .dashboard-left {
    padding: 15px 15px;
  }
  .card {
    padding: 15px 15px;
  }
  .card .card-box {
    padding-left: 15px;
  }
  table {
    width: 470px;
  }
  .dropping-area {
    flex-direction: column;
  }
  .dropping-area .box-btn {
    margin: 15px 0 0 0;
  }
  .modal-dialog {
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
  }
  .modal-dialog .modal-content .modal-body {
    padding: 15px;
  }
  .invoice-media .info-holder {
    max-width: 18px;
    width: 100%;
  }
  .invoice-media .text-box {
    padding-left: 5px;
  }
  .invoice-media .text-box h2 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
  .invoice-media .text-box p {
    font-size: 12px;
  }
  .invoice-modal-inner {
    padding-left: 23px;
  }
  .type-success .type-success-right {
    padding: 16px;
    margin-left: 16px;
  }
  .todo-setup ul li {
    margin: 0 0 8px 0;
  }
  .mobile-menu .service-list ol {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .mobile-menu .service-list ol li {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .mobile-menu .service-list ol li:last-child {
    margin: 0;
  }
  .mobile-menu {
    padding: 16px 15px;
    top: 51px;
  }
  .service-list ol li {
    margin-right: 10px;
  }
  .alert-properties-main .alert-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }
  .alert-properties-main .alert-box ol {
    width: 100%;
    justify-content: flex-end;
    margin: 15px 0 0 0;
  }
  .alert-properties-main .alert-box ol li {
    margin-right: 8px !important;
    margin-bottom: 0 !important;
  }
  .alert-properties-main .TypePrimary-Rest {
    padding: 0px 7px;
  }
}
@media (max-width: 374px) {
  .card {
    padding: 15px;
  }
  .card .card-box {
    padding-left: 15px;
  }
  .card .image-holder {
    max-width: 50px;
    width: 100%;
  }
  .card .card-box ul li p {
    font-size: 12px;
  }
  .logo {
    margin-left: 10px;
  }
  .language-list ul li button {
    font-size: 12px;
    padding: 0 3px 2px;
  }
  .call-btn {
    font-size: 12px;
  }
  .call-btn img {
    margin-right: 6px;
    height: 12px;
  }
  .logo img {
    height: 20px;
  }
}
.searchBar .btn-outline-secondary,
.searchBar .btn-outline-secondary:active,
.searchBar .btn-outline-secondary:focus {
  color: #1469bac4 !important;
  border: none;
}
.searchBar .btn-outline-secondary:hover {
  color: #1469ba69 !important;
}
.searchBar .btn-outline-secondary:focus {
  box-shadow: none !important;
}
.searchBar .input-group .btn {
  position: relative;
  z-index: 999;
}
.searchBar .btn-outline-custom {
  color: #929292 !important;
  border: none;
}
